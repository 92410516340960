import { useLayoutEffect, useState } from 'react';
import { createFileRoute, Link, redirect, useNavigate, useRouter } from '@tanstack/react-router';
import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Link as ChakraLink,
  Text,
  HStack,
  Image,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuthContext } from '@/context/auth/useAuthContext';
import appMessages from '@/messages';
import authMessages from '@/messages/auth';
import authRoutes from '@/constants/routes/auth';
import AuthenticationPage from '@/components/templates/AuthenticationPage';
import iconsPng from '@/assets/img/png/icons';
import { TCustomAxiosError } from '@/services/api';
import { EMAIL_REGEX } from '@/constants/stringVars';

type FormData = {
  email: string;
  password: string;
};

// NOTE: If we expect some custom validation rules, consider moving yup instance to separate file and import from there
const schema = yup
  .object({
    email: yup.string().matches(EMAIL_REGEX, 'Please enter valid email address.').required('Email is required.'),
    password: yup.string().required('Password is required.'),
  })
  .required();

const Login = () => {
  const { link } = Route.useSearch();
  const router = useRouter();
  const navigate = useNavigate({ from: '/login' });
  const { login, isAuthenticated } = useAuthContext();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState('');

  const { mutateAsync: handleLogin, isPending: isHandleLoginPending } = useMutation({
    mutationFn: async (data: FormData) => {
      setCustomErrorMessage('');
      await login(data.email, data.password);
      router.invalidate();
    },
    onError: (err: TCustomAxiosError) => {
      console.log('err', err);
      // NOTE: this is example of handling api error beside form error. Remove if not needed
      setCustomErrorMessage(err?.errors[0]?.message);

      toast({
        title: 'An error occurred.',
        description: err?.errors[0]?.message ?? err.toString(),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  useLayoutEffect(() => {
    if (isAuthenticated) {
      if (link) {
        window.location.replace(link);
        return;
      }

      // if (isAdmin) {
      //   navigate({
      //     to: '/organization/dashboard',
      //   });
      //   return;
      // }

      // if (isUserWithDataNotSet) {
      //   navigate({
      //     to: '/onboarding/user',
      //   });
      //   return;
      // }

      // if (isUserWithDataSet) {
      //   navigate({
      //     to: '/user/dashboard',
      //   });
      //   return;
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, navigate]);

  return (
    <AuthenticationPage>
      <Text
        variant={'urbanistSemiBold'}
        textColor={'text.veryLightBlue'}
        color={'background.lightBlue'}
        opacity={0.7}
        position={'relative'}
        marginTop={'20px'}
        maxWidth={'400px'}
      >
        {appMessages.loginDescription}
      </Text>
      <Box minW={{ md: '295px' }} marginTop={'40px'} alignItems={'center'}>
        <form onSubmit={handleSubmit((data) => handleLogin(data))}>
          <Stack spacing={4} p="1rem">
            <FormControl isInvalid={!!errors.email}>
              <Input
                className="custom-input"
                {...register('email', {
                  onChange: () => {
                    if (customErrorMessage) setCustomErrorMessage('');
                  },
                })}
                placeholder={appMessages.email}
              />
              <Text color={'extra.red'}>{errors.email?.message ?? ''}</Text>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <InputGroup>
                <Input
                  className="custom-input"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={appMessages.password}
                  {...register('password', {
                    onChange: () => {
                      if (customErrorMessage) setCustomErrorMessage('');
                    },
                  })}
                />
                <InputRightElement width="4rem" height={'100%'}>
                  <Button onClick={() => setShowPassword(!showPassword)}>
                    <Image
                      src={showPassword ? iconsPng.eyeOpened : iconsPng.eyeClosed}
                      width={25}
                      height={25}
                      alt={showPassword ? 'eyeOpened' : 'eyeClosed'}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text color={'extra.red'}>{errors.password?.message ?? ''}</Text>
              <Box position={'relative'}>
                <Text
                  color={'extra.orange'}
                  fontWeight={'600'}
                  position={'absolute'}
                  top={'2'}
                  width={'100%'}
                  textAlign={'center'}
                >
                  {customErrorMessage ?? ''}
                </Text>
              </Box>
            </FormControl>
            <Button
              type="submit"
              variant="formSubmit"
              width="full"
              isLoading={isHandleLoginPending}
              isDisabled={!isValid}
              disabled={!isValid}
              marginTop={'40px'}
            >
              {authMessages.signIn}
            </Button>
          </Stack>
        </form>
        <HStack width={'100%'} justifyContent={'center'}>
          <ChakraLink
            as={Link}
            to={authRoutes.forgotPassword}
            color="text.veryLightBlue"
            fontSize={'16px'}
            fontWeight={'600'}
            lineHeight={'20px'}
            textAlign={'center'}
          >
            {authMessages.forgotPassword}
          </ChakraLink>
        </HStack>
      </Box>
    </AuthenticationPage>
  );
};

export const Route = createFileRoute('/login/')({
  component: Login,
  validateSearch: (search: Record<string, unknown>) => {
    // Validate search params here. We only want valid url as link param
    if (search.link === 'https://www.blablabla.com/contact/support') {
      return {
        link: search.link,
      };
    }

    delete search.link;
    return {};
  },
  beforeLoad: async ({ context: { auth } }) => {
    const isAuthenticated = auth?.isAuthenticated;
    if (isAuthenticated) {
      throw redirect({
        to: '/onboarding/user',
      });
    }
  },
});
