import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer';
import colors from '@/theme/colors';
import { HStack, Text, VStack } from '@chakra-ui/react';

interface OrganizationRosterDeleteItemModalProps {
  deleteConfirmationModalVisible: boolean;
  setDeleteConfirmationModalVisible: (modalVisible: boolean) => void;
  deleteItem: (itemId: string) => void;
  itemToDeleteId: string;
  itemToDeleteEmail: string;
}

const OrganizationRosterDeleteItemModal = ({
  deleteConfirmationModalVisible,
  setDeleteConfirmationModalVisible,
  deleteItem,
  itemToDeleteId,
  itemToDeleteEmail,
}: OrganizationRosterDeleteItemModalProps) => {
  return (
    <ModalContainer
      isBlur={false}
      isOpen={deleteConfirmationModalVisible}
      width={"50%"}
      height={"20vh"}
      backgroundColor="white"
      closeButtonColor="black"
      footerContent={
        <HStack justifyContent={"center"} width={"100%"} height={"100%"}>
          <CustomButton
            label="Cancel"
            labelHoverColor="black"
            onClick={() => {
              setDeleteConfirmationModalVisible(false);
            }}
            style={{ marginTop: "20px", fontSize: "18px" }}
            height={"60px"}
            width={"100px"}
          />
          <CustomButton
            label="Delete"
            labelHoverColor={colors.extra.red}
            onClick={() => {
              deleteItem(itemToDeleteId);
              setDeleteConfirmationModalVisible(false);
            }}
            style={{ marginTop: "20px", fontSize: "18px" }}
            height={"60px"}
            width={"100px"}
          />
        </HStack>
      }
      onClose={() => setDeleteConfirmationModalVisible(false)}
    >
      <VStack marginTop={"20px"}>
        <Text variant={"loraSmallTitle"} color={"black"}>
          Confirm Deletion
        </Text>
        <Text fontSize={"20px"} marginTop={"20px"}>
          Are you sure you want to delete {itemToDeleteEmail}?
        </Text>
      </VStack>
    </ModalContainer>
  );
};

export default OrganizationRosterDeleteItemModal;
