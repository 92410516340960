export const employeeUserRoutes = {
  userDashboard: '/user/dashboard',
  userProfile: '/user/profile',
  userProfileDemographics: '/user/profile/demographics',
  userProfileContact: '/user/profile/contact',
  screener: '/screener',
  assessment: '/assessment',
  onboardingUser: '/onboarding/user',
  onboardingWholenessScreener: '/onboarding/wholeness-screener',
}

export const orgAdminRoutes = {
  ...employeeUserRoutes,
  organizationDashboard: '/organization/dashboard',
  organizationRoster: '/organization/roster',
  onboardingOrganization: '/onboarding/organization',
}

const appRoutes = {
  ...orgAdminRoutes,
}

export default appRoutes