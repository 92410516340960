import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated')({
  component: Outlet,
  beforeLoad: async ({ context: { auth } }) => {
    const isAuthenticated = auth?.isAuthenticated;
    if(!isAuthenticated) {
      throw redirect({
        to: '/login',
      });
    }
  },
});
