import { TOrganization } from "@/types/Organization.types"
import { api, TCustomAxiosResponse } from ".."
import { TRepresentativeFormData } from "@/modules/onboarding/organization/types"
import { TUser } from "@/types/User.types"

const root = 'v1/organization'

const endpoints = {
  organization: `${root}`,
  myOrganization: `${root}/my`,
  inviteUsers: `${root}/invite/users`,
  members: `${root}/my/users`,
  deleteOrgMember: `${root}/employee`,
  logoUpload: `${root}/my/upload-logo`,
}

export const myOrganizationGet = async (): Promise<TOrganization> => {
  const { data } = await api.get<TCustomAxiosResponse<TOrganization>>(endpoints.myOrganization)
  const organization = data.data
  return organization
}

export const updateOrganizationByIdPut = async (organizationId: string, orgInfo: TRepresentativeFormData) => {
  const { data } = await api.put<TCustomAxiosResponse<TOrganization>>(`${endpoints.organization}/${organizationId}`, {
    ...orgInfo
  })
  const updatedOrganization = data.data
  return updatedOrganization
}

export const inviteUsersToOrganizationPost = async (users: {email: string, phone: string, firstName: string, lastName: string}[]) => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(endpoints.inviteUsers, {
    users
  })

  return data.data
}

export const uploadOrganizationLogoPost = async (file: File): Promise<TOrganization> => {
  const formData = new FormData()
  formData.append('file', new File([file], file.name))

  const { data } = await api.post<TCustomAxiosResponse<TOrganization>>(endpoints.logoUpload, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  const organization = data.data
  return organization
}

export const organizationMembersForAdminGet = async (): Promise<TUser[]> => {
  const { data } = await api.get(endpoints.members);
  return data.data;
};

export const removeMemberFromOrganization = async (
  orgMemberId: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<TCustomAxiosResponse<any>> => {
  const { data } = await api.delete(
    `${endpoints.deleteOrgMember}/${orgMemberId}`,
  );
  return data; // TODO: mozda dodaj .data
};