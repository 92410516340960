import { background, text } from "../colors/palettes"

const tableTheme = {
  Table: `
    padding: 40px;
    padding-bottom: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 10px 80px 0px #00417926;
  `,
  Header: "",
  Body: "",
  BaseRow: `
    font-size: 16px;
    padding: 10px;
  `,
  HeaderRow: `
    font-size: 12px;
    font-weight: bold;
    color: ${text.mediumGray};
  `,
  Row: `
    color: ${text.mediumBlue};
    font-weight: 600;

    &.disabled {
      color: ${background.lightGray};
    }

    &:hover {
      color: ${text.mediumBlue};
    }

    &:not(:last-of-type) > .td {
      border-bottom: 1px solid ${background.separatorBlue};
    }
  `,
  BaseCell: ``,
  HeaderCell: `
    font-weight: bold;
    border-bottom: 0;
    
  `,
  Cell: `
    padding: 20px 20px 20px 0;

    &:focus {
      outline: dotted;
      outline-width: 1px;
      outline-offset: -1px;
    }
  `,
}

export default tableTheme