import type { CSSProperties } from "react";

import { HStack, Text, Checkbox } from "@chakra-ui/react";
import { FORM_FIELD_WIDTH } from "@/constants/dimensions";

const CheckBoxTile = ({
  label,
  isChecked,
  onClick,
  noOfLines,
}: {
  label: string;
  isChecked: boolean;
  onClick: (_: string) => void;
  buttonStyle?: CSSProperties;
  noOfLines?: number;
}) => {
  return (
    <HStack
      justifyContent={"space-between"}
      background={"extra.white"}
      boxShadow={isChecked ? "" : `0px 2px 8px 0px #00417926`}
      borderRadius={8}
      padding={"16px"}
      width={FORM_FIELD_WIDTH}
      onClick={(e) => {
        e.preventDefault(); // necessary to prevent double-event from clicking the checkbox
        onClick(label);
      }}
      borderWidth={2}
      borderColor={isChecked ? "primary.500" : "transparent"}
      cursor="pointer"
      _hover={{ boxShadow: isChecked ? "" : `0px 2px 16px 0px #00417926` }}
    >
      <Text
        fontWeight={600}
        noOfLines={noOfLines}
        maxWidth={"240px"}
        textOverflow={"elipsis"}
        fontSize={"14px"}
        color={isChecked ? "primary.500" : "text.darkBlue"}
      >
        {label}
      </Text>
      <Checkbox 
        isChecked={isChecked}
        colorScheme="primary"
        backgroundColor={'background.darkGray'}
        borderColor={'background.darkGray'}
        borderRadius={'4px'}
        size={'lg'}
        justifyContent={'center'}
        alignItems={'center'}
      />
    </HStack>
  );
};

export default CheckBoxTile;