import Markdown from "@/components/atoms/Markdown/Markdown";
import { Button, HStack, VStack, Text, Image } from "@chakra-ui/react";
import DocumentSliderForm from "@/components/molecules/DocumentSliderForm";
import DocumentSlide from "@/components/molecules/DocumentSlide";
import ControlledSliderExtended from "@/components/molecules/SliderExtended/ControlledSliderExtended";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: Record<string, any> = {
  Markdown: Markdown,
  Button: Button,
  VStack: VStack,
  HStack: HStack,
  Slider: ControlledSliderExtended,
  ASSESSMENT: DocumentSliderForm,
  SCREENER: DocumentSliderForm,
  QuestionContainer: DocumentSlide,
  Text,
  Image
}

export default components;