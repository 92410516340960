import { useMemo, useState } from 'react';

import photosPng from '@/assets/img/png/photos';
import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import Loader from '@/components/atoms/Loader';
import Calendly from '@/components/molecules/Calendly';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import { EMPTY_WELLNESS_DOMAINS } from '@/constants';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { mixpanelEvents } from '@/constants/mixpanel';
import { employeeUserRoutes } from '@/constants/routes/app';
import UserWellnessGoal from '@/modules/user/UserWellnessGoal';
import { useAppStore } from '@/store/useAppStore';
import colors from '@/theme/colors';
import { getAssessmentsResultAverageScores } from '@/utils/queryOptions';
import { capitalize } from '@/utils/stringUtils';
import { Box, Card, Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';

const UserDashboard = () => {
  const { data: userWellnessScores } = useSuspenseQuery(getAssessmentsResultAverageScores());
  const { user: userData } = useAppStore();
  const { trackEvent } = useTelemetry();
  const navigate = useNavigate({ from: employeeUserRoutes.userDashboard });

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const isLoading = !userData || userWellnessScores === EMPTY_WELLNESS_DOMAINS;

  const wellnessDataSorted = useMemo(() => {
    const wellnessDataArray = userWellnessScores
      ? Object.entries(userWellnessScores).map(([wellnessDomain, wellnessValue]) => ({
          domainName: capitalize(wellnessDomain),
          value: wellnessValue,
        }))
      : [];

    wellnessDataArray.sort((a, b) => (a.value > b.value ? 1 : -1));
    return wellnessDataArray;
  }, [userWellnessScores]);

  const calculatedWellnessGoalData = useMemo(() => {
    return [
      {
        name: wellnessDataSorted[0].domainName,
        value: wellnessDataSorted[0].value > 0 ? 100 / wellnessDataSorted[0].value : 100,
      },
      {
        name: wellnessDataSorted[1].domainName,
        value: wellnessDataSorted[1].value > 0 ? 100 / wellnessDataSorted[1].value : 100,
      },
      {
        name: wellnessDataSorted[2].domainName,
        value: wellnessDataSorted[2].value > 0 ? 100 / wellnessDataSorted[2].value : 100,
      },
    ];
  }, [wellnessDataSorted]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <VStack width={MAX_CONTAINER_WIDTH}>
      {/* Actual page content */}
      <VStack width={'100%'} marginBottom={'50px'}>
        <HStack justify={'space-between'} width={'100%'} marginBottom={'50px'}>
          <Text variant="loraTitle" textAlign={'start'}>
            Your Strengths & Opportunities for Growth
          </Text>
          <HStack gap={8}>
            <CustomButton
              onClick={() => navigate({ to: '/screener/$screenerId', params: { screenerId: 'wholeness-screener' } })}
              isTransparent
              label="Retake Screener"
              iconBeforeLabelSvgPath={svgIcons.assessment}
              iconBeforeLabelSvgColor={colors.text.darkBlue}
              labelColor={colors.text.darkBlue}
              hoverBackgroundColor="extra.white"
              borderHoverColor="transparent"
            />
            <CustomButton
              backgroundColor="primary.500"
              labelColor="extra.white"
              onClick={() => alert('Requesting . . .')}
              label="Request Peer Support"
              disabled
            />
          </HStack>
        </HStack>

        <WellnessStateCircle wellnessData={userWellnessScores} userAvatar={userData?.user_metadata.profilePicture} />
        <Text variant={'urbanistSmall'} color={'text.mediumGray'}>
          These results are not to be interpreted as a Diagnosis, but are meant to guide you in your wellness decisions
        </Text>
        <UserWellnessGoal data={calculatedWellnessGoalData} />

        {/* Call emergency */}
        <Box
          backgroundColor={'background.cloudGray'}
          marginY={'120px'}
          paddingY={'40px'}
          paddingX={'80px'}
          borderRadius={'12px'}
          fontSize={'16px'}
          lineHeight={'20px'}
          fontWeight={500}
          maxWidth={'600px'}
        >
          <Text display={'inline'} variant={'lora'} fontStyle={'italic'}>
            If you feel like you are having a medical emergency{' '}
          </Text>
          <Text display={'inline'} fontWeight={800} color={'primary.500'}>
            dial 911
          </Text>
          <VStack display={'inline-block'} marginTop={'20px'} textAlign={'center'}>
            <Text display={'inline'} variant={'lora'} fontSize={'16px'} lineHeight={'20px'} fontStyle={'italic'}>
              If you are considering suicide, or struggling with your mental health and are having a crisis{' '}
            </Text>
            <Text display={'inline'} fontWeight={800} color={'primary.500'}>
              dial 988
            </Text>
          </VStack>
        </Box>

        {/* BOBBI */}
        <Text variant={'loraTitle'} color={'text.darkBlue'} marginBottom={'50px'}>
          Ready to speak with someone for support?
        </Text>
        <Card size="sm" width={'400px'} variant={'elevated'} borderRadius={'8px'} padding={'20px'}>
          <Center flexDirection={'column'} width="100%" marginTop={'-20px'} paddingBottom={'20px'}>
            <Image src={photosPng.bobbi} alt="bobbi-avatar" width={200} height={200} />
            <VStack marginTop={'-20px'}>
              <Text
                variant={'lora'}
                fontSize={'22px'}
                lineHeight={'22px'}
                fontWeight={500}
                color={'text.darkBlue'}
                fontStyle={'italic'}
              >
                Bobbi McGraw
              </Text>
              <Text fontWeight={700} fontSize={'14px'}>
                Our Member Care Specialist
              </Text>
              <Text
                fontWeight={500}
                fontSize={'16px'}
                lineHeight={'19px'}
                textAlign={'center'}
                color={'primary.darkBlue'}
                paddingX={'10px'}
              >
                Bobbi has more than 25 years of experience working with youth and victims of crime. She has degrees in
                Special Education, English, and Psychology, and is trained in forensic interviewing. Bobbi has
                substantial experience in the field of advocacy in sexual assault, domestic violence, and human
                trafficking and crisis response.
              </Text>
            </VStack>
          </Center>
        </Card>

        <CustomButton
          label="Schedule call"
          backgroundColor="primary.500"
          labelColor="extra.white"
          onClick={() => {
            setIsCalendlyOpen(true);
            trackEvent(mixpanelEvents.MEMBER_CARE_CALL_SCHEDULED);
          }}
          width={'300px'}
          style={{ marginTop: '30px' }}
        />
        <Calendly
          isOpen={isCalendlyOpen}
          setIsOpen={setIsCalendlyOpen}
          url={'https://calendly.com/bobbi-mcgraw-previdence/call-with-member-care'}
        />
      </VStack>

      {/* <Text variant="loraTitle">Error fetching wellness data for: {userData?.email}</Text> */}

      {/* TODO: removing for now */}
      {/* <OrganizationFeed /> */}
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_user/user/dashboard')({
  component: UserDashboard,
  loader: async ({ context }) => context.queryClient.ensureQueryData(getAssessmentsResultAverageScores()),
});
