import Loader from '@/components/atoms/Loader';
import UserProfileOverview from '@/components/organisms/UserProfileOverview';
import AppPageTemplate from '@/components/templates/AppPageTemplate'
import useToast from '@/hooks/useToast';
import { uploadProfilePhoto } from '@/services/api/requests/user';
import { useAppStore } from '@/store/useAppStore';
import { Button } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import React, { useEffect } from 'react'


const ProfileOverview = () => {
  const toast = useToast();
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 500, behavior: 'smooth' }), 1500);
  },[])

  const { user: userData, setUser } = useAppStore();

  const uploadPhoto = async (profilePhoto: File) => {
    try {
      const data = await uploadProfilePhoto(profilePhoto);
      setUser(data);
      toast({
        status: 'success',
        title: 'Success',
        description: 'Profile Photo updated successfully!',
        position: 'top-right',
      });
      return true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return false;
    }
  };

  if (!userData) return <Loader />;

  // TODO: Implement ProfileOverview and Image Upload
  return (
    <AppPageTemplate>
      <UserProfileOverview
        title={'Create Your Profile'}
        updateProfilePhoto={uploadPhoto}
        userData={{ ...userData.user_metadata, email: userData.email }}
        onClickEditContactDetails={() => {}}
        onClickEditDemographics={() => {}}
        initialPreview={userData?.user_metadata.profilePicture}
        hideEditContactButton={true}
        hideEditDemographicsButton={true}
      />
      <Button variant={'formSubmit'} marginBottom={'30px'} onClick={() => { navigate({ to: '/onboarding/wholeness-screener'})}}>
        Proceed
      </Button>
    </AppPageTemplate>
  )
}

export default ProfileOverview