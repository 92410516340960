import { useMutation, useQueryClient } from "@tanstack/react-query"
import useToast from "../useToast"
import { TCustomAxiosError } from "@/services/api"
import { useScreenerStore } from "@/store/useScreenerStore"
import { getScoresPerDomainForFormValues } from "@/utils/screenersScores"
import { averageScoresGet, createAssessmentResultPost } from "@/services/api/requests/result"
import { useAppStore } from "@/store/useAppStore"
import { useNavigate } from "@tanstack/react-router"
import useTelemetry from "@/providers/TelemetryProvider/useTelemetry"
import { mixpanelEvents } from "@/constants/mixpanel"
import { THealthDomainQuestionType } from "@/types/Health.types"

const useAssessmentResult = (onSuccessCallback?: () => void) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { trackEvent } = useTelemetry()
  
  const { setUserScores } = useAppStore()
  const { form: formDomains, screenerId, slug } = useScreenerStore()

  const { isPending, mutate } = useMutation({
    mutationFn: async ({ formValues }: { formValues: Record<string, {value: number, type: THealthDomainQuestionType}> }) => {
      const scoresPerDomain = getScoresPerDomainForFormValues(formValues, formDomains)
      const response = await createAssessmentResultPost(screenerId, formValues, scoresPerDomain)

      const scores = await averageScoresGet()
      setUserScores(scores)

      return response
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assessments-result-average-scores'] })
      
      toast({
        title: 'Success',
        description: 'Result saved successfully',
        status: 'success',
      })

      onSuccessCallback?.()

      trackEvent(mixpanelEvents.SCREENER_FINISH, {
        screenerId: screenerId,
        screenerSlug: slug,
      })

      navigate({
        to: '/user/dashboard',
      })

      window.scrollTo({top: 0, behavior: 'instant'})

      return

    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      })
    }
  })


  return {isPending, mutate}
}

export default useAssessmentResult