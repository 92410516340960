import COOKIE_KEYS from '@/constants/cookies';
import { TUser, TUserContactInfo, TUserDemographicsInfoWithoutPicture } from '@/types/User.types';
import { demographicsFeToBe, userBeToFe } from '@/utils/demographicDataMappers';
import Cookies from 'js-cookie';
import { api, TCustomAxiosResponse } from '..';

const rootPath = '/v1/user';

const endpoints = {
  user: `${rootPath}`,
  login: `${rootPath}/login`,
  forgotPassword: `${rootPath}/forgot-password`,
  uploadProfilePic: `${rootPath}/upload-profile-picture`,
};

export const userGet = async (): Promise<TUser> => {
  const { data } = await api.get(endpoints.user);
  const user = userBeToFe(data.data) as TUser;

  return user;
};

export const loginPost = async (email: string, password: string) => {
  const tokenData = await api.post('/v1/user/login', {
    email,
    password,
  });

  const token = tokenData.data.data.access_token;
  const tokenType = tokenData.data.data.token_type;

  // First set token in cookies securely
  Cookies.set(COOKIE_KEYS.ACCESS_TOKEN, `${tokenType} ${token}`, {
    expires: +import.meta.env.VITE_TOKEN_EXPIRATION as number,
    secure: true,
    sameSite: 'strict',
  });
  // Add token to api headers
  api.defaults.headers.Authorization = `${tokenType} ${token}`;
};

export const forgotPasswordPost = async (email: string) => {
  await api.post(endpoints.forgotPassword, {
    email,
  });

  return 'ok';
};

export const updateUserNSubmitEulaPut = async (
  info: TUserContactInfo,
): Promise<TUser> => {
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { email, ...restForm } = info;

  const { data } = await api.put<TCustomAxiosResponse<TUser>>(endpoints.user, {
    ...restForm,
  });

  const user = data.data;

  return user;
};

export const updateUserDemoGraphicsPut = async (info: TUserDemographicsInfoWithoutPicture): Promise<TUser> => {
  const userMapped = demographicsFeToBe(info);

  const { data } = await api.put<TCustomAxiosResponse<TUser>>(endpoints.user, {
    ...userMapped,
  });

  const user = userBeToFe(data.data);
  return user;
};

export const uploadProfilePhoto = async (photo: File): Promise<TUser> => {
  const formData = new FormData();
  formData.append('file', new File([photo], photo.name));

  const { data } = await api.post(endpoints.uploadProfilePic, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return userBeToFe(data.data);
};

export const updateUserContactDetails = async (userContactDetails: Omit<TUserContactInfo, 'email'>): Promise<TUser> => {
  const { data } = await api.put(endpoints.user, userContactDetails);

  const updatedUser: TUser = userBeToFe(data.data);
  return updatedUser;
};
