import { THealthDomain, THealthDomainScores } from "@/types/Health.types"
import { api, TCustomAxiosResponse } from ".."

const root = '/v1/assessments/result'

const endpoints = {
  allResults: `${root}`,
  myAverageScores: `${root}/average-scores`,
  create: `${root}/create`,
}

export const averageScoresGet = async (): Promise<THealthDomainScores> => {
  const { data } = await api.get(endpoints.myAverageScores)
  return data.data
}

export const createAssessmentResultPost = async (assessmentId: string, formAnswers: Record<string, number | unknown>, scoresPerDomain: Record<THealthDomain, number>): Promise<unknown> => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(endpoints.create, {
    assessmentId,
    data: formAnswers,
    scores: scoresPerDomain
  })

  const response = data.data;

  return response
}