import { OPTION_OTHER } from "@/utils/demographicDataMappers";
import RadioButton from "./RadioButton";
import { HStack } from "@chakra-ui/react";


const RadioButtonGroup = ({
  items,
  selectedItem,
  onClick,
  textInputValue,
  setTextInputValue,
  noOfLines = 1,
}: {
  items: string[];
  selectedItem: string;
  onClick: (_: string) => void;
  textInputValue?: string;
  setTextInputValue?: (_: string) => void;
  noOfLines?: number;
}) => {
  return (
    <HStack gap={5} flexWrap={"wrap"}>
      {items.map((item) => (
        <RadioButton
          key={item}
          label={item}
          isSelected={selectedItem === item}
          hasTextInput={item === OPTION_OTHER && selectedItem === item}
          textInputValue={textInputValue}
          setTextInputValue={setTextInputValue}
          onClick={() => onClick(item)}
          noOfLines={noOfLines}
        />
      ))}
    </HStack>
  );
};

export default RadioButtonGroup;
