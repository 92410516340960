// option values

import { TUser, TUserDemographicsInfoWithoutPicture } from "@/types/User.types";

export const OPTION_OTHER = "Other (please specify)";
export const OPTION_OTHER_BE_VALUE = "OTHER";

export const SexBeFe = {
  MALE: "Male",
  FEMALE: "Female",
  NOT_TO_SAY: "Prefer not to say",
  OTHER: OPTION_OTHER,
};
export const SexFeBe = {
  Male: "MALE",
  Female: "FEMALE",
  "Prefer not to say": "NOT_TO_SAY",
  [OPTION_OTHER]: OPTION_OTHER_BE_VALUE,
};

export const SEX_OPTIONS = Object.keys(SexFeBe);

export const OccupationBeFe = {
  FIRST_RESPONDER: "First Responder",
  HEALTHCARE: "Healthcare",
  PUBLIC_SERVICE: "Public Service",
  EDUCATION: "Education",
  CONSTRUCTION: "Construction",
  HOSPITALITY: "Hospitality",
  TRANSPORTATION: "Transportation",
  MANUFACTURING: "Production/Manufacturing",
  SOFTWARE: "Technology/Software",
  MINING: "Mining",
  [OPTION_OTHER_BE_VALUE]: OPTION_OTHER,
};
export const OccupationFeBe = {
  "First Responder": "FIRST_RESPONDER",
  Healthcare: "HEALTHCARE",
  "Public Service": "PUBLIC_SERVICE",
  Education: "EDUCATION",
  Construction: "CONSTRUCTION",
  Hospitality: "HOSPITALITY",
  Transportation: "TRANSPORTATION",
  "Production/Manufacturing": "MANUFACTURING",
  "Technology/Software": "SOFTWARE",
  Mining: "MINING",
  [OPTION_OTHER]: OPTION_OTHER_BE_VALUE,
};

export const OCCUPATIONS = Object.keys(OccupationFeBe);

//
export const EthnicityBeFe = {
  INDIAN_ALSKA: "American Indian or Alaska Native",
  ASIAN: "Asian",
  BLACK_AFRICAN: "Black of African American",
  HISPANIC_LATINO: "Hispanic or Latino",
  HAWAIIAN_PACIFIC: "Native Hawaiian or Other Pacific Islands",
  WHITE: "White",
  MORE_RACES: "Two or More Races",
  NOT_TO_SAY: "Prefer not to say",
  [OPTION_OTHER_BE_VALUE]: OPTION_OTHER,
};
export const EthnicityFeBe = {
  "American Indian or Alaska Native": "INDIAN_ALSKA",
  Asian: "ASIAN",
  "Black of African American": "BLACK_AFRICAN",
  "Hispanic or Latino": "HISPANIC_LATINO",
  "Native Hawaiian or Other Pacific Islands": "HAWAIIAN_PACIFIC",
  White: "WHITE",
  "Two or More Races": "MORE_RACES",
  "Prefer not to say": "NOT_TO_SAY",
  [OPTION_OTHER]: OPTION_OTHER_BE_VALUE,
};

export const ETHNICITIES = Object.keys(EthnicityFeBe);

export const RelationshipStatusBeFe = {
  SINGLE: "Single",
  IN_A_RELATIONSHIP: "In a relationship",
  MARRIED: "Married",
  DIVORCED: "Divorced",
  WIDOWED: "Widowed",
  SEPARATED: "Separated",
  COHABITING: "Cohabiting",
  NOT_TO_SAY: "Prefer not to say",
  [OPTION_OTHER_BE_VALUE]: OPTION_OTHER,
};
export const RelationshipStatusFeBe = {
  Single: "SINGLE",
  "In a relationship": "IN_A_RELATIONSHIP",
  Married: "MARRIED",
  Divorced: "DIVORCED",
  Widowed: "WIDOWED",
  Separated: "SEPARATED",
  Cohabiting: "COHABITING",
  "Prefer not to say": "NOT_TO_SAY",
  [OPTION_OTHER]: OPTION_OTHER_BE_VALUE,
};
export const RELATIONSHIP_STATUSES = Object.keys(RelationshipStatusFeBe);

export const LevelOfEducationBeFe = {
  SCHOOLING_NOT_COMPLETED: "No schooling completed",
  HIGH_SCHOOL_NO_DIPLOMA: "Some high school, no diploma",
  HIGH_SCHOOL_W_DIPLOMA:
    "High school graduate, diploma or the equivalent (for example: GED)",
  COLLEGE_NO_DEGREE: "Some college, no degree",
  VOCATIONAL_TRAINING: "Trade/technical/vocational training",
  ASSOCIATE_DEGREE: "Associate degree",
  BACHELOR_DEGREE: "Bachelor's degree",
  MASTERS_DEGREE: "Master's degree",
  PROFESSIONAL_DEGREE: "Professional degree (e.g., MD, DDS, DVM)",
  DOCTORATE_DEGREE: "Doctorate degree",
  NOT_TO_SAY: "Prefer not to say",
  [OPTION_OTHER_BE_VALUE]: OPTION_OTHER,
};
export const LevelOfEducationFeBe = {
  "No schooling completed": "SCHOOLING_NOT_COMPLETED",
  "Some high school, no diploma": "HIGH_SCHOOL_NO_DIPLOMA",
  "High school graduate, diploma or the equivalent (for example: GED)":
    "HIGH_SCHOOL_W_DIPLOMA",
  "Some college, no degree": "COLLEGE_NO_DEGREE",
  "Trade/technical/vocational training": "VOCATIONAL_TRAINING",
  "Associate degree": "ASSOCIATE_DEGREE",
  "Bachelor's degree": "BACHELOR_DEGREE",
  "Master's degree": "MASTERS_DEGREE",
  "Professional degree (e.g., MD, DDS, DVM)": "PROFESSIONAL_DEGREE",
  "Doctorate degree": "DOCTORATE_DEGREE",
  "Prefer not to say": "NOT_TO_SAY",
  [OPTION_OTHER]: OPTION_OTHER_BE_VALUE,
};
export const LEVELS_OF_EDUCATION = Object.keys(LevelOfEducationFeBe);

export const LearningStyleBeFe = {
  VIDEOS: "Watch videos",
  ARTICLES: "Read articles",
  ACTIVITY: "Do an activity",
  DISCUSSION: "Have a discussion",
};
export const LearningStyleFeBe = {
  "Watch videos": "VIDEOS",
  "Read articles": "ARTICLES",
  "Do an activity": "ACTIVITY",
  "Have a discussion": "DISCUSSION",
};

export const LEARNING_STYLES = Object.keys(LearningStyleFeBe);

export const demographicsFeToBe = (user: TUserDemographicsInfoWithoutPicture) => {
  const weight = user.weight ? `${user.weight}` : null;

  const userDTO = {
    ...user,
    weight,
    sex: SexFeBe[user.sex as keyof typeof SexFeBe],
    occupation: OccupationFeBe[user.occupation as keyof typeof OccupationFeBe],
    ethnicity: EthnicityFeBe[user.ethnicity as keyof typeof EthnicityFeBe],
    relationshipStatus:
      RelationshipStatusFeBe[
      user.relationshipStatus as keyof typeof RelationshipStatusFeBe
      ],
    education:
      LevelOfEducationFeBe[user.education as keyof typeof LevelOfEducationFeBe],
    learningStylePreference: user.learningStylePreference.map(
      (learniStyleFe) =>
        LearningStyleFeBe[learniStyleFe as keyof typeof LearningStyleFeBe],
    ),
  };

  return userDTO;
};

export const userBeToFe = (user: TUser) => {
  let weight = 0;

  if (user.user_metadata?.weight) {
    weight = parseInt(user.user_metadata?.weight.toString())
  }


  const userDTO: TUser = {
    ...user,
    user_metadata: {
      ...user.user_metadata,
      weight,
      sex: SexBeFe[user.user_metadata.sex as keyof typeof SexBeFe],
      occupation:
        OccupationBeFe[
        user.user_metadata.occupation as keyof typeof OccupationBeFe
        ],
      ethnicity:
        EthnicityBeFe[
        user.user_metadata.ethnicity as keyof typeof EthnicityBeFe
        ],
      relationshipStatus:
        RelationshipStatusBeFe[
        user.user_metadata
          .relationshipStatus as keyof typeof RelationshipStatusBeFe
        ],
      education:
        LevelOfEducationBeFe[
        user.user_metadata.education as keyof typeof LevelOfEducationBeFe
        ],
      learningStylePreference: user.user_metadata.learningStylePreference?.map(
        (learniStyleFe) =>
          LearningStyleBeFe[learniStyleFe as keyof typeof LearningStyleBeFe],
      ),
    },
  };

  return userDTO;
};
