const queryKeys = {
  auth: { user: 'user-auth' },
  organization: {
    members: 'org-members',
  },
  legal: {
    privacyPolicy: 'legal-privacy-policy',
    termsOfUse: 'legal-terms-of-use',
  },
  documents: {
    screener: 'screener-documents',
  }
};

export default queryKeys;
