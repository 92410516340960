import { createContext, useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { api } from '@/services/api';
import { useQuery } from '@tanstack/react-query';
import { isUserWithData } from '@/utils/isUserWithData';
import { useUserOnboardingStore } from '@/store/useUserOnboardingStore';
import { loginPost, userGet } from '@/services/api/requests/user';
import COOKIE_KEYS from '@/constants/cookies';
import { TUser } from '@/types/User.types';
import roles from '@/constants/roles';
import { myOrganizationGet } from '@/services/api/requests/organization';
import { averageScoresGet } from '@/services/api/requests/result';
import { useAppStore } from '@/store/useAppStore';
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore';
import queryKeys from '@/constants/queryKeys';
import { acceptedDocumentsGet } from '@/services/api/requests/legal';
import { TLegalDocumentAcceptanceStatus } from '@/types/Legal.types';
import { THealthDomainScores } from '@/types/Health.types';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import LoadingPage from '@/components/templates/LoadingPage';
import { useScreenerStore } from '@/store/useScreenerStore';

export type TAuthContext = {
  user: TUser | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isUserWithDataNotSet: boolean;
  isUserWithDataSet: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
};

export const AuthContext = createContext<TAuthContext | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { 
    user,
    setAppStoreOnLogin
  } = useAppStore()

  const { handleLogin, handleLogout } = useTelemetry();

  const isAuthenticated = !!user;
  const isAdmin = !!user?.roles.includes(roles.orgAdmin);

  const isUserWithDataSet = isUserWithData(user);
  const isUserWithDataNotSet = !isUserWithDataSet && !isAdmin;

  const handleUserDataFlow = async () => {
    const requests = []

    requests.push(userGet())
    requests.push(averageScoresGet())
    requests.push(acceptedDocumentsGet())

    const responses = await Promise.all(requests)

    const userData = responses[0] as TUser
    const scoresResponse = responses[1] as THealthDomainScores
    const eulaResponse = responses[2] as TLegalDocumentAcceptanceStatus[];

    const isOrgAdmin = userData.roles.includes(roles.orgAdmin);
    let organizationResponse = null;
    if(isOrgAdmin){
      organizationResponse = await myOrganizationGet();
    }

    setAppStoreOnLogin(userData, scoresResponse, eulaResponse, organizationResponse);

    handleLogin(userData.user_id);
  }


  const login = useCallback(async (email: string, password: string) => {
    await loginPost(email, password);

    await handleUserDataFlow();
  }, []);

  const { isPending } = useQuery({
    queryKey: [queryKeys.auth.user],
    queryFn: async () => {
      // Get token form cookie if exists
      const token = Cookies.get(COOKIE_KEYS.ACCESS_TOKEN);

      if (!token) {
        return null;
      }

      // Add token to api headers
      api.defaults.headers.Authorization = token;

      await handleUserDataFlow();
    },
  });

  const logout = useCallback(async () => {
    Cookies.remove(COOKIE_KEYS.ACCESS_TOKEN);
    api.defaults.headers.Authorization = '';
    
    handleLogout(user?.user_id ?? 'id-not-provided');
    
    /**
     * On logout reset all persisted stores. If more stores are added, we can reset them in one go
     * see https://zustand.docs.pmnd.rs/guides/how-to-reset-state for more details if needed
     */
    useUserOnboardingStore.getState().reset();
    useOrganizationOnboardingStore.getState().reset();
    useAppStore.getState().reset();
    useScreenerStore.getState().reset();
  }, []);

  const auth = useMemo(
    () => ({ 
      isAuthenticated, 
      user, 
      isUserWithDataNotSet, 
      isUserWithDataSet, 
      isAdmin, 
      login, 
      logout,
     }),
    [
      isAuthenticated, 
      user, 
      login, 
      logout, 
      isUserWithDataNotSet, 
      isUserWithDataSet, 
      isAdmin,  
    ],
  );

  return <AuthContext.Provider value={auth}>{isPending ? <LoadingPage/> : children}</AuthContext.Provider>;
};
