import { FileRoutesByTo } from './routeTree.gen.ts';
import { THealthDomainScores } from './types/Health.types.ts';

type TRoutes = keyof FileRoutesByTo;

type TRouteKey = {
  label: string;
  route: TRoutes;
};

// NOTE: Duplicated routes can be extracted to a shared constant if labels are the same
export const AUTH_ROUTES_PERMISSION: {
  admin: TRouteKey[];
  userWithDataSet: TRouteKey[];
  userWithNoDataSet: TRouteKey[];
} = {
  admin: [
    {
      label: 'Organization',
      route: '/organization/dashboard',
    },
    {
      label: 'User Dashbaord',
      route: '/user/dashboard',
    },
  ],
  userWithDataSet: [
    {
      label: 'User Dashbaord',
      route: '/user/dashboard',
    },
  ],
  userWithNoDataSet: [
    {
      label: 'Onboarding',
      route: '/onboarding/user',
    },
  ],
};

export const NON_AUTH_ROUTES: TRouteKey[] = [
  {
    label: 'Support',
    route: '/contact-support',
  },
  {
    label: 'Terms of Service',
    route: '/terms-of-use',
  },
  {
    label: 'Privacy Policy',
    route: '/privacy-policy',
  },
];

export const EMPTY_WELLNESS_DOMAINS: THealthDomainScores = {
  mental: 0,
  physical: 0,
  social: 0,
  spiritual: 0,
  vocational: 0,
  purpose: 0,
  fun: 0,
};

export const WELLNESS_DOMAIN_QUESTION_TYPES = ['wellness_level', 'readiness_level'] as const;

export const NO_OF_WELLNESS_DOMAINS = Object.values(EMPTY_WELLNESS_DOMAINS).length;

export const WELLNESS_DATA_MULTIPLIER = 10;

export const WELLNESS_STATE_LIMIT_DANGER = 33;
export const WELLNESS_STATE_LIMIT_OK = 66;
