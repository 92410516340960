import React, { useCallback, useMemo, useState } from 'react';
import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { TUserDemographicsInfo } from '@/types/User.types';
import { HEIGHT_FEET_VALIDATION, HEIGHT_INCHES_VALIDATION, WEIGHT_VALIDATION } from '@/constants/validations';
import RadioButtonGroup from '@/components/molecules/RadioButtonGroup';
import {
  ETHNICITIES,
  LEARNING_STYLES,
  LEVELS_OF_EDUCATION,
  OCCUPATIONS,
  OPTION_OTHER,
  RELATIONSHIP_STATUSES,
  SEX_OPTIONS,
} from '@/utils/demographicDataMappers';
import CheckBoxTile from '@/components/atoms/CheckBoxTile';
import { useForm } from 'react-hook-form';

type Props = {
  submitLabel?: string;
  onSubmit?: (data: TUserDemographicsInfo) => void;
  isLoading?: boolean;
  defaultFormValues: TUserDemographicsInfo | null;
};

const DemographicsForm = ({ defaultFormValues, onSubmit, isLoading = false, submitLabel = 'Continue' }: Props) => {
  const eightTeenYearsAgoTimeStamp = new Date().setFullYear(new Date().getFullYear() - 18);
  const eightTeenYearsAgo = new Date(eightTeenYearsAgoTimeStamp).toISOString().split('T')[0];

  const oneHundredsYearsAgoTimeStamp = new Date().setFullYear(new Date().getFullYear() - 100);
  const oneHundredsYearsAgo = new Date(oneHundredsYearsAgoTimeStamp).toISOString().split('T')[0];

  const { register, setValue, watch, formState, getValues } = useForm<TUserDemographicsInfo>({
    defaultValues: { ...defaultFormValues },
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { isValid: isValidInputFields, defaultValues, errors } = formState;

  const [otherSex, setOtherSex] = useState(defaultValues?.sex === OPTION_OTHER ? defaultFormValues?.sexOther : '');
  const selectedSex = watch('sex');

  const [otherOccupation, setOtherOccupation] = useState(
    defaultValues?.occupation === OPTION_OTHER ? defaultFormValues?.occupationOther : '',
  );
  const selectedOccupation = watch('occupation');

  const [otherEthnicity, setOtherEthnicity] = useState(
    defaultValues?.ethnicity === OPTION_OTHER ? defaultFormValues?.ethnicityOther : '',
  );
  const selectedEthnicity = watch('ethnicity');

  const [otherRelationshipStatus, setOtherRelationshipStatus] = useState(
    defaultValues?.relationshipStatus === OPTION_OTHER ? defaultFormValues?.relationshipStatusOther : '',
  );
  const selectedRelationshipStatus = watch('relationshipStatus');

  const [otherLevelOfEducation, setOtherLevelOfEducation] = useState(
    defaultValues?.education === OPTION_OTHER ? defaultFormValues?.educationOther : '',
  );
  const selectedLevelOfEducation = watch('education');

  const selectedLearningStylePreference = watch('learningStylePreference');

  const updateLearningStyles = useCallback(
    (value: string) => {
      if (selectedLearningStylePreference?.includes(value)) {
        const newLearningStylePreference = selectedLearningStylePreference.filter(
          (learningStyle) => learningStyle !== value,
        );
        setValue('learningStylePreference', newLearningStylePreference);
      } else {
        setValue('learningStylePreference', [...(selectedLearningStylePreference || []), value]);
      }
    },
    [selectedLearningStylePreference, setValue],
  );

  const getFormDataWithOtherOptions = useCallback((): TUserDemographicsInfo => {
    const allValues: TUserDemographicsInfo = getValues();

    if (allValues.sex === OPTION_OTHER) {
      allValues.sexOther = otherSex;
    }
    if (allValues.occupation === OPTION_OTHER) {
      allValues.occupationOther = otherOccupation;
    }
    if (allValues.ethnicity === OPTION_OTHER) {
      allValues.ethnicityOther = otherEthnicity;
    }
    if (allValues.relationshipStatus === OPTION_OTHER) {
      allValues.relationshipStatusOther = otherRelationshipStatus;
    }
    if (allValues.education === OPTION_OTHER) {
      allValues.educationOther = otherLevelOfEducation;
    }

    return { ...defaultFormValues, ...allValues };
  }, [
    getValues,
    otherSex,
    otherOccupation,
    otherEthnicity,
    otherRelationshipStatus,
    otherLevelOfEducation,
    defaultFormValues,
  ]);

  const isFormValid = useMemo(() => {
    if (!selectedSex || (selectedSex === OPTION_OTHER && !otherSex)) {
      return false;
    }
    if (!selectedOccupation || (selectedOccupation === OPTION_OTHER && !otherOccupation)) {
      return false;
    }
    if (!selectedEthnicity || (selectedEthnicity === OPTION_OTHER && !otherEthnicity)) {
      return false;
    }
    if (!selectedRelationshipStatus || (selectedRelationshipStatus === OPTION_OTHER && !otherRelationshipStatus)) {
      return false;
    }
    if (!selectedLevelOfEducation || (selectedLevelOfEducation === OPTION_OTHER && !otherLevelOfEducation)) {
      return false;
    }
    if (!selectedLearningStylePreference?.length) return false;

    return isValidInputFields;
  }, [
    selectedSex,
    selectedOccupation,
    selectedEthnicity,
    selectedRelationshipStatus,
    selectedLevelOfEducation,
    otherSex,
    otherOccupation,
    otherEthnicity,
    otherRelationshipStatus,
    otherLevelOfEducation,
    selectedLearningStylePreference,
    isValidInputFields,
  ]);

  const onFormSubmit = () => {
    const formData = getFormDataWithOtherOptions();
    if (onSubmit) {
      onSubmit(formData);
    }
  };

  return (
    <VStack spacing={'48px'}>
      <VStack alignItems={'center'} width={'100%'}>
        <HStack spacing={'20px'} minWidth={'100%'}>
          <VStack flex={1} alignItems={'flex-start'}>
            <Text>Date of Birth</Text>
            <Input
              type="date"
              placeholder="Date of Birth"
              className="custom-input"
              _placeholder={{ color: 'text.mediumGray', fontWeight: '600', fontSize: 12 }}
              color={'text.darkGray'}
              max={eightTeenYearsAgo}
              min={oneHundredsYearsAgo}
              {...register('dateOfBirth', { required: true })}
            />
          </VStack>
          <HStack flex={1} spacing={'10px'}>
            <VStack alignItems={'flex-start'} flex={1}>
              <Text>Height</Text>
              <HStack minWidth={'100%'} width={'100%'}>
                <Input
                  type="number"
                  placeholder="Feet"
                  className="custom-input"
                  _placeholder={{ color: 'text.mediumGray', fontWeight: '600', fontSize: 13 }}
                  color={'text.darkGray'}
                  isInvalid={!!errors?.heightFeet}
                  min={HEIGHT_FEET_VALIDATION.min.value}
                  max={HEIGHT_FEET_VALIDATION.max.value}
                  {...register('heightFeet', {
                    required: true,
                    valueAsNumber: true,
                    ...HEIGHT_FEET_VALIDATION,
                  })}
                  minWidth={'70px'}
                />
                <Input
                  type="number"
                  placeholder="Inches"
                  className="custom-input"
                  _placeholder={{ color: 'text.mediumGray', fontWeight: '600', fontSize: 13 }}
                  color={'text.darkGray'}
                  isInvalid={!!errors?.heightInches}
                  min={HEIGHT_INCHES_VALIDATION.min.value}
                  max={HEIGHT_INCHES_VALIDATION.max.value}
                  {...register('heightInches', {
                    required: true,
                    valueAsNumber: true,
                    ...HEIGHT_INCHES_VALIDATION,
                  })}
                  minWidth={'70px'}
                />
              </HStack>
            </VStack>
            <VStack flex={1} alignItems={'flex-start'}>
              <Text>Weight</Text>
              <Input
                type="number"
                placeholder="Weight (lbs)"
                className="custom-input"
                _placeholder={{ color: 'text.mediumGray', fontWeight: '600', fontSize: 13 }}
                color={'text.darkGray'}
                isInvalid={!!errors?.weight}
                min={WEIGHT_VALIDATION.min.value}
                max={WEIGHT_VALIDATION.max.value}
                {...register('weight', {
                  required: true,
                  valueAsNumber: true,
                  ...WEIGHT_VALIDATION,
                })}
              />
            </VStack>
          </HStack>
        </HStack>
        <Text variant={'error'}>
          {errors?.dateOfBirth?.message ||
            errors?.heightFeet?.message ||
            errors?.heightInches?.message ||
            errors?.weight?.message ||
            ''}
        </Text>
      </VStack>
      <VStack>
        <Text marginBottom={'16px'}>Sex</Text>
        <RadioButtonGroup
          items={SEX_OPTIONS}
          selectedItem={selectedSex}
          onClick={(sex: string) => setValue('sex', sex)}
          textInputValue={otherSex}
          setTextInputValue={setOtherSex}
        />
      </VStack>
      <VStack>
        <Text marginBottom={'16px'}>Occupation</Text>
        <RadioButtonGroup
          items={OCCUPATIONS}
          selectedItem={selectedOccupation}
          onClick={(occupation: string) => setValue('occupation', occupation)}
          textInputValue={otherOccupation}
          setTextInputValue={setOtherOccupation}
        />
      </VStack>
      <VStack>
        <Text marginBottom={'16px'}>Ethnicity</Text>
        <RadioButtonGroup
          items={ETHNICITIES}
          selectedItem={selectedEthnicity}
          onClick={(ethnicity: string) => setValue('ethnicity', ethnicity)}
          textInputValue={otherEthnicity}
          setTextInputValue={setOtherEthnicity}
        />
      </VStack>
      <VStack>
        <Text marginBottom={'16px'}>Relationship Status</Text>
        <RadioButtonGroup
          items={RELATIONSHIP_STATUSES}
          selectedItem={selectedRelationshipStatus}
          onClick={(relationshipStatus: string) => setValue('relationshipStatus', relationshipStatus)}
          textInputValue={otherRelationshipStatus}
          setTextInputValue={setOtherRelationshipStatus}
        />
      </VStack>
      <VStack>
        <Text marginBottom={'16px'}>Highest level of education you have completed</Text>
        <RadioButtonGroup
          items={LEVELS_OF_EDUCATION}
          selectedItem={selectedLevelOfEducation}
          noOfLines={2}
          onClick={(education: string) => setValue('education', education)}
          textInputValue={otherLevelOfEducation}
          setTextInputValue={setOtherLevelOfEducation}
        />
      </VStack>
      <VStack>
        <Text marginBottom={'16px'}>Choose your learning style preference</Text>
        <HStack flexWrap={'wrap'} gap={5}>
          {LEARNING_STYLES.map((learningStyle) => (
            <CheckBoxTile
              onClick={updateLearningStyles}
              isChecked={selectedLearningStylePreference?.includes(learningStyle)}
              key={learningStyle}
              label={learningStyle}
            />
          ))}
        </HStack>
      </VStack>
      <Button
        onClick={onFormSubmit}
        variant={'formSubmit'}
        marginTop={'12px'}
        marginBottom={'48px'}
        isDisabled={!isFormValid || isLoading}
        disabled={!isFormValid || isLoading}
        isLoading={isLoading}
      >
        {submitLabel}
      </Button>
    </VStack>
  );
};

export default DemographicsForm;
